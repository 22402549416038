<template>
  <div>
    <div class="flix-form-group">
      <div class="flix-input-group">
        <span class="flix-input-group-addon">
          <flixIcon id="link" />
        </span>
        <input type="text" class="flix-form-control" placeholder="https://..." v-model="url">
        <span class="flix-input-group-btn">
          <a href="#" class="flix-btn flix-btn-default" @click.prevent="addTargetPage">{{ $t('message.save', {name: ''}) }}</a>
        </span>
      </div>
    </div>
    <transition name="flixFadeIn">
      <div class="flix-form-group" v-if="saved">
        <div class="flix-alert flix-alert-success">{{ $t('message.successfullSaved') }}</div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    edit: {
      type: Object,
      default () { return {} }
    },
    data: {
      type: Array,
      default () { return [] }
    },
    variables: {
      type: Object,
      default () { return {} }
    },
    callback: {
      type: Function,
      default () { return function () {} }
    }
  },
  data () {
    return {
      url: this.getUrl(),
      saved: false
    }
  },
  methods: {
    getUrl () {
      if (this.edit.method === 'customUrl') {
        return this.edit.layout[0]
      }
      return ''
    },
    addTargetPage () {
      if (!this.url) {
        return false
      }

      var data = {
        ID: 'tp-' + this.$route.params.id,
        assistents: [this.$route.params.id],
        method: 'customUrl',
        layout: [this.url]
      }

      this.$store.dispatch('setTargetPage', {
        user: this.variables.user.md5_id,
        ID: data.ID,
        data: data.layout,
        meta: { assistents: data.assistents, method: data.method },
        callback: function () {
          this.saved = true
          setTimeout(function () {
            this.callback(data)
          }.bind(this), 2000)
        }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
